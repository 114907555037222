@import "_fonts.sass";
@import "_main.sass";

.main-home {
    background: url('../img/bg_main.jpg') no-repeat center;
    background-size: cover;
    padding-bottom: 180px;
    position: relative;
    text-align: center;
    overflow-x: hidden;
    @media(min-width: 1200px) {
        background: transparent; }

    .container {
        position: relative;
        z-index: 3; }

    .overlay {
        background-color: rgba(147, 93, 0, 0.8);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1; }

    .fa {
        border-right: 1px solid #fff;
        display: block;
        float: left;
        font-size: 28px;
        margin-right: 20px;
        padding: 5px 20px 5px 0;
        width: 38px; }

    .fa, p {
        color: #fff;
        text-align: left;
        position: relative;
        z-index: 1; }

    .right-col {
        margin-bottom: 30px;
        @media(min-width: 768px) {
            margin-bottom: 110px; }

        p {
            @media(min-width: 768px) {
                text-align: right; } }
        .fa {

            @media(min-width: 768px) {
                border-left: 1px solid #fff;
                border-right: none;
                float: right;
                margin-right: 0;
                margin-left: 20px;
                padding: 5px 0 5px 20px; } } }


    &__h1 {
        color: #fff;
        font-size: 26px;
        margin-top: 15px;
        margin-bottom: 105px;
        position: relative;
        z-index: 1;
        @media(min-width: 768px) {
            margin-top: 25px; } }

    &__logo {
        position: relative;
        margin-top: 5.5em;
        width: 260px;
        z-index: 1;
        @media(min-width: 768px) {
            // margin-top: 4.5em
            width: auto; } }


    &__play-btn {
        @extend %play-btn;
        margin-top: 40px;
        margin-bottom: 50px;
        @media(min-width: 992px) {
            margin-bottom: 0; } }

    &__link-down {
        color: #fff !important;
        text-decoration: none !important;
        z-index: 1;
        span {
            &:before {
                content: '\f063';
                font-family: 'FontAwesome'; } } } }



.main-numbers {
    background-image: linear-gradient(to top, #e2e0d9 0%, white 25%, white 42%, #e4e1d9 77%, rgba(228, 225, 217, 0.48) 89%, transparent 99%, transparent 100%);
    padding: 0 0 5em;
    position: relative;
    z-index: 2;
    // &:before
    //     content: ''
    //     background: url('../img/milk-bg.png') no-repeat top
    //     display: block
    //     position: absolute
    //     top: -300px
    //     height: 366px
    //     width: 110%

    &__item {
        border: 10px solid #e7e5e4;
        height: 250px;
        max-width: 190px;
        margin: 70px auto;
        transition: all .3s ease;
        @media(min-width: 768px) {
            height: 220px; }
        @media(min-width: 992px) {
            height: 200px; }
        @media(min-width: 1200px) {
            height: 235px; }

        &:hover {
            border-color: #5aa909;
            transform: scale(1.2);

            h3 {
                color: $green; } }

        .img-responsive {
            margin-top: -50px; }

        h3 {
            font-size: 30px;
            font-weight: 700;
            text-align: center;

            span {
                display: block;
                font-size: 18px;
                font-weight: 600;
                padding: 0 5px; } } }

    &__text {
        position: relative;
        &:before {
            content: '';
            background: url('../img/notice.png') no-repeat center -5px;
            display: block;
            width: 60px;
            height: 65px;
            margin: 10px auto 20px;
            left: 0;
            right: 0;

            @media(min-width: 768px) {
                margin: 0;
                top: -10px;
                left: -80px;
                position: absolute; } } } }


.main-about {
    @extend %bg-home;
    padding: 4em 0 8em;
    position: relative;
    &:after,
    &:before {
        content: '';
        bottom: 0;
        position: absolute;
        width: 50%; }

    &:before {
        border-bottom: 20px solid #fff;
        border-right: 15px solid transparent;
        left: 0; }

    &:after {
        border-bottom: 20px solid #fff;
        border-left: 15px solid transparent;
        right: 0; }




    &__btn {
        border: 2px solid #fff;
        border-radius: 6px;
        box-shadow: 0 7px 14px rgba(0, 0, 0, 0.18);
        color: #fefefe;
        display: block;
        font-size: 18px;
        line-height: 40px;
        margin-top: 50px;
        height: 45px;
        text-decoration: none !important;
        text-align: center;
        transition: all .2s;
        width: 100%;
        @media(min-width: 768px) {
            width: 216px; }

        &:hover {
            background: #fff;
            color: $dark-grey; }
        &:focus {
            background: #e7e7e7;
            border-color: #e7e7e7;
            color: $dark-grey; }
        &:active {
            color: $dark-grey; } }


    &__img-block {
        border: 10px solid rgba(255, 255, 255, 0.2);
        max-width: 312px;
        margin: 110px auto 0;
        text-align: center;
        padding-bottom: 25px;
        line-height: 19px;

        h3 {
            font-size: 18px;
            font-weight: 600;
            padding: 0 20px;
            margin: 15px 0 5px; }

        .img-responsive {
            margin-top: -100px; } } }

.main-target {
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.18);

    &__cite-top,
    &__cite-bottom {
        color: $dark-grey;
        font-size: 80px;
        font-weight: 900;
        text-align: center;
        opacity: 0.2; }

    // &__cite-top
    //     &:before
    //         content: ''
    //         border-right: 2px solid #f1f1f1
    //         display: block
    //         height: 46px
    //         width: 50%
    //         @media(min-width: 768px)
    //             height: 70px

    // &__cite-bottom
    //     &:after
    //         content: ''
    //         border-right: 2px solid #f1f1f1
    //         display: block
    //         height: 46px
    //         width: 50%
    //         @media(min-width: 768px)
    //             height: 70px



    &__overlay-text {
        opacity: 0.05;
        color: #5aa909;
        font-family: "NexaScript";
        font-size: 200px;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        z-index: -1;
        overflow-x: hidden; }

    &__text {
        font-size: 14px;
        font-style: italic;
        text-align: center;
        @media(min-width: 992px) {
            font-size: 24px; } }

    &__signature {
        margin: 0 auto 70px;
        width: 270px;
        p {
            margin-bottom: 0; } }


    &__signature-chief {
        float: left;
        display: block;
        margin-top: -15px;
        margin-right: 10px; }

    &__signature-img {
        margin-top: 15px; }


    &__signature-name {
        font-weight: 700; } }


.main-products {
    @extend %products;

    &__btn-info {
        @extend %products__btn-info; } }



.main-products-slider {
    @extend %products-slider;

    &__item {
        @extend %products-slider__item; }

    &__item-img {
        @extend %products-slider__item-img; }

    &__item-title {
        @extend %products-slider__item-title; } }


.main-products-card {
    @extend %products-card;

    &__badge {
        @extend %products-card__badge; }

    &__btn {
        @extend %products-card__btn; }


    &__row {
       @extend %products-card__row; } }


.main-steps {
    background-image: linear-gradient(to top, #e1ded7 0%, white 25%, white 42%, #e4e1d9 77%, rgba(228, 225, 217, 0.48) 89%, transparent 99%, transparent 100%);
    padding: 50px 0 70px;

    &__btn {
        @extend %btn;
        margin: 30px 0 40px;
        font-size: 17px;
        font-weight: 600;
        padding: 10px 20px;
        width: 100%;

        @media(min-width: 768px) {
            padding: 10px 30px;
            width: 320px; } }


    &__switcher {
        border: 2px solid $dark-grey;
        border-radius: 6px;
        list-style: none;
        margin: 45px auto;
        padding-left: 0;
        height: 40px;
        width: 100%;
        @media(min-width: 768px) {
            width: 370px; }

        li {
            display: block;
            float: left;
            height: 100%;
            text-align: center;
            transition: all .2s;
            width: 50%;

            a {
                color: $dark-grey;
                display: block;
                font-weight: 600;
                height: 100%;
                line-height: 36px;
                text-decoration: none !important; }

            &.active {
                background: $dark-grey;
                a {
                    color: #fff; } } } }

    &__steps-list {
        list-style: none;
        text-align: center;
        padding-left: 0;
        max-width: 940px;
        min-height: 100px;
        margin: 90px auto 20px;
        &:before {
            content: '';
            border-top: 2px dashed #000;
            display: none;
            opacity: .2;
            margin: 0 100px -28px;
            position: relative;
            z-index: 1;
            @media(min-width: 768px) {
                display: block; }

            @media(min-width: 992px) and (max-width: 1199px) {
                margin: 0 200px -28px; } }


        &:after {
            content: '';
            clear: both;
            display: table; } }



    &__steps-item {
        position: relative;
        z-index: 2;

        @media(min-width: 768px) {
            display: inline-block; }

        a {
            background: #fff;
            border-radius: 50%;
            box-shadow: 0 7px 14px rgba(0, 0, 0, 0.18);
            display: block;
            margin: 0 auto 70px;
            width: 50px;
            height: 50px;
            text-align: center;
            text-decoration: none !important;
            transition: all .2s;
            position: relative;
            @media(min-width: 768px) {
                margin: 0 35px 0; }
            @media(min-width: 1200px) {
                margin: 0 60px 0; } }


        &.active {
            a {
                background: $green;
                transform: scale(1.1);


                .main-steps__steps-number {
                    color: #fff; }

                .main-steps__steps-title {
                        color: $green; } } } }

    &__steps-number {
        color: $dark-grey;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        line-height: 50px; }

    &__steps-title {
        color: $dark-grey;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        width: 120px;
        position: absolute;
        top: 60px;
        left: 50%;
        margin-left: -60px;
        line-height: 17px; }

    &__step-tag {
        background: $green;
        border-radius: 10px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        display: inline-block;
        padding: 2px 10px; }

    &__h3 {
        font-weight: 600; }

    &__img-block {
        border: 10px solid rgba(29,13,2,.2);
        max-width: 312px;
        margin: 110px auto 0;
        text-align: center;
        padding-bottom: 25px;
        line-height: 19px;

        h3 {
            font-size: 18px;
            font-weight: 600;
            padding: 0 20px;
            margin: 15px 0 5px; }

        .img-responsive {
            margin-top: -100px; } } }


.main-map {
    @extend %map; }

.main-map-contacts {
    @extend %map-contacts;

    &__h2 {
        @extend %map-contacts__h2; }


    &__row {
        @extend %map-contacts__row; } }


.main-h2 {
    @extend %h1; }

.main-video {
    display: none;
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    z-index: 0;
    @media(min-width: 1200px) {
        display: block; } }
