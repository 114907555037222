@font-face {
    font-family: "ProximaNova";
    src: url("../fonts/ProximaNova/ProximaNova-Regular.eot");
    src: url("../fonts/ProximaNova/ProximaNova-Regular.woff") format("woff"),
    url("../fonts/ProximaNova/ProximaNova-Regular.woff2") format("woff2"),
    url("../fonts/ProximaNova/ProximaNova-Regular.ttf") format("truetype") {}
    url('./fonts/ProximaNova/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg'),
    url("../fonts/ProximaNova/ProximaNova-Regular.eot?#iefix")format("embedded-opentype") {}
    font-style: normal;
    font-weight: normal; }

@font-face {
    font-family: "ProximaNova";
    src: url("../fonts/ProximaNova/ProximaNova-RegularIt.eot");
    src: url("../fonts/ProximaNova/ProximaNova-RegularIt.woff") format("woff"),
    url("../fonts/ProximaNova/ProximaNova-RegularIt.woff2") format("woff2"),
    url("../fonts/ProximaNova/ProximaNova-RegularIt.ttf") format("truetype") {}
    url('./fonts/ProximaNova/ProximaNova-RegularIt.svg#ProximaNova-RegularIt') format('svg'),
    url("../fonts/ProximaNova/ProximaNova-RegularIt.eot?#iefix")format("embedded-opentype") {}
    font-style: italic;
    font-weight: normal; }

@font-face {
    font-family: "ProximaNova";
    src: url("../fonts/ProximaNova/ProximaNova-Bold.eot");
    src: url("../fonts/ProximaNova/ProximaNova-Bold.woff") format("woff"),
    url("../fonts/ProximaNova/ProximaNova-Bold.woff2") format("woff2"),
    url("../fonts/ProximaNova/ProximaNova-Bold.ttf") format("truetype") {}
    url('./fonts/ProximaNova/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg'),
    url("../fonts/ProximaNova/ProximaNova-Bold.eot?#iefix")format("embedded-opentype") {}
    font-style: normal;
    font-weight: 700; }

@font-face {
    font-family: "ProximaNova";
    src: url("../fonts/ProximaNova/ProximaNova-Semibold.eot");
    src: url("../fonts/ProximaNova/ProximaNova-Semibold.woff") format("woff"),
    url("../fonts/ProximaNova/ProximaNova-Semibold.woff2") format("woff2"),
    url("../fonts/ProximaNova/ProximaNova-Semibold.ttf") format("truetype") {}
    url('./fonts/ProximaNova/ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg'),
    url("../fonts/ProximaNova/ProximaNova-Semibold.eot?#iefix")format("embedded-opentype") {}
    font-style: normal;
    font-weight: 600; }

@font-face {
    font-family: "ProximaNova";
    src: url("../fonts/ProximaNova/ProximaNova-Light.eot");
    src: url("../fonts/ProximaNova/ProximaNova-Light.woff") format("woff"),
    url("../fonts/ProximaNova/ProximaNova-Light.woff2") format("woff2"),
    url("../fonts/ProximaNova/ProximaNova-Light.ttf") format("truetype") {}
    url('./fonts/ProximaNova/ProximaNova-Light.svg#ProximaNova-Light') format('svg'),
    url("../fonts/ProximaNova/ProximaNova-Light.eot?#iefix")format("embedded-opentype") {}
    font-style: normal;
    font-weight: 300; }

@font-face {
    font-family: "NexaScript";
    src: url("../fonts/NexaScript/NexaScript-Regular.eot");
    src: url("../fonts/NexaScript/NexaScript-Regular.woff") format("woff"),
    url("../fonts/NexaScript/NexaScript-Regular.woff2") format("woff2"),
    url("../fonts/NexaScript/NexaScript-Regular.ttf") format("truetype") {}
    url('./fonts/NexaScript/NexaScript-Regular.svg#NexaScript-Regular') format('svg'),
    url("../fonts/NexaScript/NexaScript-Regular.eot?#iefix")format("embedded-opentype") {}
    font-style: normal;
    font-weight: normal; }

@font-face {
    font-family: "NexaScript";
    src: url("../fonts/NexaScript/NexaScript-Bold.eot");
    src: url("../fonts/NexaScript/NexaScript-Bold.woff") format("woff"),
    url("../fonts/NexaScript/NexaScript-Bold.woff2") format("woff2"),
    url("../fonts/NexaScript/NexaScript-Bold.ttf") format("truetype") {}
    url('./fonts/NexaScript/NexaScript-Bold.svg#NexaScript-Bold') format('svg'),
    url("../fonts/NexaScript/NexaScript-Bold.eot?#iefix")format("embedded-opentype") {}
    font-style: normal;
    font-weight: 700; }
