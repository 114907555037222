@import "_fonts.sass";
@import "_main.sass";

.contacts {

	&__h1 {
		@extend %h1; }

	&__h2 {
		@extend %map-contacts__h2; }

	&__row {
		@extend %map-contacts__row; } }

.contacts-block {
	@extend %map-contacts; }

.contacts-map {
	@extend %map;
	height: 700px; }

.contacts-dropdown {
	border-bottom: 2px solid $green;
	font-size: 18px;
	font-weight: 600;
	margin: 0 15px;
	&-name {
		float: left;
		display: block;
		padding: 5px 25px; }

	.fa {
		font-size: 26px;
		left: 5px;
		top: 3px; }

	.chevron {
		font-family: 'FontAwesome';
		font-size: 12px;
		float: right;
		font-weight: normal;
		color: $green;
		display: block;
		margin: 8px 0;
		&:before {
			content: '\f078'; } }

	&.open {
        .chevron:before {
            content: '\f077'; } }


	.dropdown-menu {
		border: 1px solid $green;
		border-radius: 0;
		font-size: 16px;
		padding: 0;
		width: 100%;
		&:before {
			display: none; } }

	.dropdown-toggle {
		display: block;

		&:after {
			content: '';
			display: table;
			clear: both; } }

	&:after {
			content: '';
			display: table;
			clear: both; } }
