/* colors */

$dark-grey: #1d0d02;
$green: #5aa909;

/* mixins */

@mixin zoomIn {
    animation-name: zoomIn;
    animation-duration: 1s; }

/* extends */

%bg-career {
    background: url('../img/bg-career.jpg') no-repeat center;
    background-size: cover;
    color: #fff;
    padding-bottom: 80px; }

%bg-home {
    background: url('../img/bg-combains.jpg') no-repeat center;
    background-size: cover;
    color: #fff;
    padding-bottom: 140px; }

%btn {
    background: transparent;
    border: 2px solid $green;
    border-radius: 6px;
    color: $green;
    cursor: pointer;
    display: block;
    font-weight: 400;
    text-align: center;
    text-decoration: none !important;
    transition: all .2s;
    outline: none;
    &:hover,
    &:focus {
        background: $green;
        color: #fff; }

    &:focus {
        background: #468a00;
        border-color: #468a00; } }

%career-block {
    border: 10px solid rgba(255, 255, 255, 0.2);
    display: block;
    height: 285px;
    max-width: 312px;
    margin: 115px auto 30px;
    text-align: center;
    padding-bottom: 25px;
    line-height: 19px;
    text-decoration: none !important;
    transition: border-color .2s;
    &:hover {
        border-color: rgba(255,255,255,.5); }

    &__title {
        color: #fff !important;
        display: block;
        font-size: 18px;
        font-weight: 600;
        padding: 20px 0; }

    &__img {
        margin-top: -110px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        max-width: 100%;
        height: auto; } }

%career-slider {
    margin: 0 auto;
    max-width: 1024px;
    width: 100%; }


%career-slider-item {
    border: 10px solid #c4c0b9;
    min-height: 366px;
    margin: 0 20px;
    outline: none !important;
    &:after {
        clear: both;
        display: table;
        content: ''; }

    &__img {
        display: block;
        max-width: 100%;
        margin: 20px auto; }


    &__h4 {
        font-weight: 600;
        margin: 20px 0 10px;
        line-height: 22px; }

    &__h3 {
        font-weight: 600;
        margin: 20px 0 15px; } }


%h1 {
    font-family: "NexaScript";
    font-size: 40px;
    font-weight: 700;
    margin: 2.2em 0 1.4em;
    text-align: center;
    position: relative;
    z-index: 3;
    @media(min-width: 768px) {
        font-size: 48px; } }

%home-text {
    padding: 0 60px;
    text-align: center;
    @media(min-width: 1200px) {
        padding: 0 280px; } }

%hex-thumb-img {
    width: 290px;
    margin: -35px -35px 0;
    position: relative;
    z-index: 1; }

%hex-thumb-block {
    border: 10px solid rgba(29,13,2,.2);
    line-height: 260px;
    height: 260px;
    width: 240px;
    margin: 0 auto;
    position: relative; }

%media-h1 {
    font-size: 30px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 30px;
    @media(min-width: 768px) {
        font-size: 36px; } }

%media-h2 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 35px;
    @media(min-width: 768px) {
        font-size: 30px; } }

%media-date {
    background: $green;
    border-radius: 9px;
    color: #fff;
    display: block;
    font-size: 12px;
    font-weight: 700;
    height: 20px;
    line-height: 21px;
    text-align: center;
    width: 52px; }

%media-btn-more {
    background: #f9f7f3;
    border: 2px solid black;
    border-radius: 6px;
    color: $dark-grey;
    display: block;
    font-size: 18px;
    font-weight: 600;
    margin: 30px auto;
    width: 216px;
    height: 45px;
    transition: background .2s;
    &:hover,
    &:focus {
        border: none; }

    &:hover {
        background: #fff; }

    &:focus {
        background: #e7e7e7; } }


%media-link-back {
    color: $green !important;
    display: block;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none !important;
    margin: 3.5em 0 1em;
    padding-left: 22px;
    position: relative;
    transition: all .2s;
    opacity: .8;
    @media(min-width: 768px) {
        font-size: 18px; }

    &:hover {
        opacity: 1; }

    &:before {
        content: '\f053';
        display: block;
        font-family: "FontAwesome";
        font-size: 19px;
        position: absolute;
        left: 0;
        bottom: -4px;
        @media(min-width: 768px) {
            font-size: 23px; } } }


%sup-block {
    margin-bottom: 50px;

    &__img {
        max-width: 100%;
        height: auto; }

    &__img-wrap {
        display: block;
        height: 155px;
        text-align: center;
        vertical-align: middle;
        line-height: 155px; }

    &__title {
        font-size: 18px;
        font-weight: 600;
        display: block;
        margin-bottom: -5px; } }

%play-btn {
    background-color: rgba(255,255,255,.1);
    border: none;
    border-radius: 50%;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
    display: block;
    position: relative;
    transition: all .2s;
    height: 113px;
    margin-left: auto;
    margin-right: auto;
    width: 113px;
    z-index: 1;
    &:hover {
        box-shadow: none; }

    &:focus {
        background-color: rgba(255,255,255,.4); }

    img {
        position: absolute;
        left: 50%;
        top: 1px;
        margin-left: -65px;
        width: 130px; } }

%products {
    background-image: radial-gradient(circle 500px at center, #8bc34a 0%, #dce775 100%);
    color: #fff;
    overflow-x: hidden;
    padding: 4em 0 5em;
    position: relative;

    &__btn-info {
        outline: none !important;
        text-decoration: none !important;
        position: absolute;
        top: -195px;
        right: -80px;
        display: none;
        opacity: 0.9;
        transition: all .2s;
        &:hover {
            opacity: 1; }

        .fa {
            font-size: 38px;
            color: #fff; }

        span {
            color: #fff;
            font-size: 13px;
            display: block;
            line-height: 1.2;
            width: 60px;
            margin-top: 5px; } } }

%products-slider {
    margin: 2em auto;
    width: 260px;
    @media(min-width: 768px) {
        width: 720px; }

    @media(min-width: 1200px) {
        width: auto; }

    &__item {
        border: 10px solid rgba(255, 255, 255, 0.2);
        float: left;
        height: 180px;
        text-align: center;
        width: 160px;
        margin: 200px 24px 50px;
        outline: none !important;
        position: relative;
        &.slick-center {
            border-color: transparent;
            img {
                margin-top: -130px;
                transform: scale(1.75); }

            .main-products__btn-info,
            .products__btn-info {
                display: block; }

            .open {
                display: block; } }

        // @media(max-width: 768px)
 }        //     width: 160 !important


    &__item-img {
        margin: -145px auto 0;
        transition: all .2s;
        width: 100px; }


    &__item-title {
        display: block;
        font-weight: 600;
        padding: 10px;
        width: 100%; }

    .slick-list {
        padding-top: 20px !important; } }


%products-card {
    display: none;
    background: #fff;
    border-radius: 6px;
    color: $dark-grey;
    text-align: left;
    font-size: 14px;
    width: 200px;
    height: 400px;
    position: absolute;
    left: -50px;
    top: -200px;
    margin: auto;
    overflow: hidden;
    @media(min-width: 768px) {
        left: 0;
        right: 0; }

    b {
        font-weight: 600; }

    &__badge {
        background: $green;
        width: 64px;
        height: 20px;
        float: left;
        font-size: 12px;
        line-height: 21px;
        border-radius: 6px 0;
        font-weight: 600;
        text-align: center;
        color: #fff; }

    &__btn {
        @extend %btn;
        font-size: 16px;
        line-height: 30px;
        width: 140px;
        height: 35px;
        position: absolute;
        bottom: 25px;
        left: 0;
        right: 0;
        margin: auto; }


    &__row {
        padding: 0 15px; }

    h3 {
        color: $dark-grey;
        font-size: 20px;
        margin-top: 30px;
        font-weight: 600;
        text-align: center; }

    .close {
        font-size: 16px;
        color: #000;
        padding: 5px; }

    .progress {
        background-color: #dfdedd;
        height: 5px; } }

%map {
    // background: url("../img/map-bg.png") no-repeat center bottom
    height: 600px;
    position: relative;

    .container {
        position: relative; } }

%map-contacts {
    background: #fff;
    min-height: 260px;
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.18);
    border-radius: 6px;
    padding: 20px 15px;
    position: absolute;
    top: 80px;
    left: 15px;
    @media(max-width:767px) {
        width: 270px; }

    &__h2 {
        font-size: 18px;
        font-weight: 600;
        margin: 15px 0 15px;
        padding: 0 15px; }

    &__row {
        line-height: 1.2;
        position: relative;
        padding-left: 45px;
        margin-bottom: 20px;
        &:after {
            clear: both;
            display: table;
            content: ''; } }

    .fa {
        position: absolute;
        left: 15px;
        top: -4px;
        color: $green;
        font-size: 24px; }

    a {
        color: transparentize($dark-grey, .2);
        text-decoration: none !important;
        transition: all .2s ease;
        &:hover {
            color: $dark-grey; } } }


/* main styles */

a, button {
    &:focus {
        outline: none; } }

html {
    height: 100%; }

body {
    color: $dark-grey;
    font-family: "ProximaNova";
    font-size: 16px;
    min-height: 100%;
    position: relative;
    padding-bottom: 300px;
    @media(min-width:768px) {
        padding-bottom: 215px; } }

canvas {
    display: block;
    width: 100%;
    height: 400px;
    position: absolute;
    bottom: 0;
    z-index: 1; }

.dropdown {
    .chevron {
        font-family: 'FontAwesome';
        font-size: 10px;
        margin-left: 2px;
        &:before {
            content: '\f078'; } }

    &.open {
        .chevron:before {
            content: '\f077'; } } }

.dropdown-menu {
    &:before {
        content: '';
        display: none;
        border: 5px solid transparent;
        border-bottom: 5px solid #fff;
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        margin: auto;
        width: 5px;
        @media(min-width: 768px) {
            display: block; } } }


.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
     @media(min-width: 768px) {
        background: transparent; } }

.nav>li>a:focus, .nav>li>a:hover {
    @media(min-width: 768px) {
        background: transparent; } }

.navbar {
    margin-bottom: 0; }

.navbar-brand {
    display: block;
    height: auto;
    padding: 7px 10px;
    margin: auto;
    float: none;
    width: 84px;

    img {
        width: 64px; }

    &--black {
        display: none; } }



.navbar-right .dropdown-menu {
    @media(min-width: 768px) {
        box-shadow: 0 7px 14px rgba(0, 0, 0, 0.18);
        left: 0;
        right: auto;
        min-width: 100%;
        margin-top: -20px !important; } }

.navbar-nav>li>a {
    color: $dark-grey;
    font-size: 16px;
    @media(min-width: 768px) {
        padding-bottom: 30px;
        padding-top: 30px; }

    @media(min-width: 992px) {
        color: #fff; } }

.navbar-nav>li.active>a {
    &:after {
        content: '';
        display: block;
        height: 2px;
        background: $green;
        @media(max-width: 767px) {
            display: none; } } }

.navbar-nav>li.active.dropdown>a {
    &:after {
        margin-right: 15px; } }

.navbar-nav>li>.dropdown-menu {
    border-radius: 6px; }

.navbar-toggle {
    margin-top: 18px;
    margin-right: 10px;


    .icon-bar {
        background: #fff;
        width: 28px;
        transition: all .2s; }

    .icon-bar + .icon-bar {
        margin-top: 8px; } }




.main-header {
    border-bottom: 1px solid rgba(255,255,255,.3);
    position: absolute;
    transition: all .2s;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;

    .container {
        @media(min-width: 768px) {
            width: 100%; }

        @media(min-width: 992px) {
            width: 970px; }

        @media(min-width: 1200px) {
            width: 1170px; } }

    &.open {

        @media(max-width: 991px) {
            background-image: linear-gradient(to top, #f4f0e8 0%, white 100%); }

        .navbar-brand {
            @media(max-width: 991px) {
                display: none; }

            &--black {
                @media(max-width: 991px) {
                    display: block; } } }

        .navbar-toggle {
            .icon-bar {
                background: #000;

                &:nth-child(3) {
                    opacity: 0; }


                &:nth-child(2) {
                    transform: rotate(45deg);
                    transform-origin: 0% 0%; }

                &:nth-child(4) {
                    transform: rotate(-45deg);
                    transform-origin: 0% 90%; } } } }

    &-white {
        background-image: linear-gradient(to top, #f4f0e8 0%, white 100%);
        border-bottom: none;
        box-shadow: 0 7px 14px rgba(0, 0, 0, 0.18);
        .navbar-nav>li>a {
            color: $dark-grey !important; }

        .navbar-brand {
            display: none;

            &--black {
                display: block; } }

        .navbar-toggle {
            .icon-bar {
                background: $dark-grey; } } } }


.header-padding {
    padding-top: 80px; }


.main-footer {
    background: $dark-grey;
    position: absolute;
    transition: all .2s;
    bottom: 0;
    left: 0;
    z-index: 2;
    min-height: 300px;
    width: 100%;
    @media(min-width: 768px) {
        min-height: 215px; } }

.main-footer-nav {
    padding: 10px 0 20px;
    @media(min-width: 768px) {
        padding: 10px 0 0; }

    p {
        color: #fff; }

    .container {
        @media(min-width: 768px) {
            width: 100%; }

        @media(min-width: 992px) {
            width: 970px; }

        @media(min-width: 1200px) {
            width: 1170px; } }

    &.open {

        @media(max-width: 991px) {
            background-image: linear-gradient(to top, #f4f0e8 0%, white 100%); }

        .navbar-brand {
            @media(max-width: 991px) {
                display: none; }

            &--open {
                @media(max-width: 991px) {
                    display: block; } } }

        .navbar-toggle {
            .icon-bar {
                background: #000;

                &:nth-child(3) {
                    opacity: 0; }


                &:nth-child(2) {
                    transform: rotate(45deg);
                    transform-origin: 0% 0%; }

                &:nth-child(4) {
                    transform: rotate(-45deg);
                    transform-origin: 0% 90%; } } } } }

.main-footer-bottom {
    padding-top: 5px;
    p {
        color: #fff; }

    &__link {
        color: #fff !important;
        display: block;
        @media(min-width: 768px) {
            text-align: right; } }

    .text-center {
        text-align: center;
        padding: 20px 0 10px; } }



/*modal*/


.modal {

    h4 {
        color: $green;
        font-size: 24px;
        font-weight: 600;
        margin: 15px 0 5px;
        text-align: center; }


    .close {
        width: 26px;
        transition: all .2s ease;
        text-align: center;
        top: 7px;
        right: 7px;
        position: absolute;
        opacity: .8;
        height: 26px;
        font-weight: 400;
        font-size: 30px;
        color: #fff;
        border-radius: 50%;
        background-color: $dark-grey;
        &:hover {
            opacity: 1; } }

    .form-group {
        margin-bottom: 18px;
        position: relative; }

    .form-group-text {
        float: left;
        font-weight: 600;
        line-height: 18px;
        padding: 10px; }

    .help-block {
        display: none;
        font-size: 12px;
        margin: 0;
        padding: 0 7px;
        position: absolute; }

    .has-error {
        .help-block {
            display: block; } }


    input[type=file] {
        display: none; }

    &-input {
        border-color: $green;
        border-width: 2px;
        border-radius: 6px;
        font-weight: 700;
        height: 32px;

        &:focus {
            border-color: #a96f09;
            box-shadow: none; } }


    input[type=submit] {
        @extend %btn;
        font: {
            size: 16px;
            weight: 600; }
        height: 41px;
        margin: 30px auto 0;
        width: 193px; }

    &-btn-file {
        @extend %btn;

        font-size: 12px;
        float: right;
        height: 30px;
        line-height: 25px;
        margin-top: 5px;
        padding: 0;
        width: 142px; }

    &-btn-error {
        @extend .modal-btn-filename;

        color: #a94442;
        display: none;
        .has-error & {
            display: inline-block; } }

    &-btn-filename {
        width: 150px;
        font-size: 12px;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        position: absolute;
        bottom: -13px;
        right: 0; }

    &-content {
        padding: 10px; }

    &-dialog {
        @media(min-width: 768px) {
            max-width: 480px; } } }

.yandex-map {
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute; }

@keyframes zoomIn {
    0% {
        transform: scale(.8); }
    100% {
        transform: scale(1); } }
